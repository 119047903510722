import React from 'react'
import { IoAirplane } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import SupportCard from '../cards/SupportCard'
import { IoIosHelp } from 'react-icons/io'
import { AiOutlineHistory } from 'react-icons/ai'

function Navbar() {
  const router = useNavigate()
  const [showModel, setShowModel] = React.useState(false)
  return (
    <div className='p-2 border-b-2 border-[#00000034] shadow-xl flex justify-between'>
      <div
        className='flex items-center gap-2 cursor-pointer'
        onClick={() => {
          router('/')
        }}
      >
        <img src='/images/logo.jpg' className='md:h-10 h-8' alt='logo' />
        <p className='text-xl text-black font-bold'> | Customize</p>
      </div>
      <div className='flex items-center gap-3'>
        <div
          className='hidden justify-center items-center sm:flex  bg-white border-2 border-[#0000002a] rounded-full px-8 cursor-pointer hover:bg-[#f7ff613d] hover:text-black text-black'
          onClick={() => {
            window.open('https://www.casemandu.com.np')
          }}
        >
          <h2 className=''>Back to Casemandu</h2>
        </div>

        <div
          className='flex justify-center items-center sm:hidden  bg-white border-2 border-[#0000002a] rounded-full cursor-pointer hover:bg-[#f7ff613d] hover:text-black text-black'
          onClick={() => {
            window.open('https://www.casemandu.com.np')
          }}
        >
          <img
            src='/images/favicon.ico'
            alt='logo'
            className='
          h-8
          '
          />
        </div>
        {/* histoy */}
        <div
          className='flex justify-center items-center bg-white border-2 border-[#0000002a] h-10 w-10 rounded-full cursor-pointer hover:bg-[#f7ff613d] hover:text-black text-black'
          onClick={() => {
            router('/history')
          }}
        >
          <AiOutlineHistory size={32} />
        </div>

        <div
          className='flex justify-center items-center bg-white border-2 border-[#0000002a] h-10 w-10 rounded-full cursor-pointer hover:bg-[#f7ff613d] hover:text-black text-black'
          onClick={() => {
            setShowModel(true)
          }}
        >
          <IoIosHelp size={32} />
        </div>
      </div>
      {(showModel) &&<div className="flex fixed h-screen p-2 w-full top-0 left-0 z-50 backdrop-blur-lg bg-[#ffffff2a] justify-center items-center">
        <SupportCard showModel={setShowModel}/>
      </div>}
      {showModel && (
        <div className='flex fixed h-screen w-full top-0 left-0 z-50 backdrop-blur-lg bg-[#ffffff2a] justify-center items-center'>
          <SupportCard showModel={setShowModel} />
        </div>
      )}
    </div>
  )
}

export default Navbar
