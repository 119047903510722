import React, { useEffect, useState } from "react";

import Cropper from "react-easy-crop";
import { getCroppedImg } from "../cropImage";
import { Tooltip } from "react-tooltip";
import { IoPencil } from "react-icons/io5";

export default function Editor({ id, image, imageUrl, ratio, data }) {
  const [height, setHeight] = useState(550); // Set initial height
  const [showModal, setShowModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [rotation, setRotation] = useState(0);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      const imageSend = await croppedImage.imageSend;

      setCroppedImage(imageSend);
      setShowModal(false);
      data({
        ...data,
        imageSend: imageSend,
        croppedAreaPixels,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!!image) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [image]);

  return (
    <div className="text-3xl md:w-2/3 w-full md:flex-row flex-col  p-3 min-h-screen flex justify-around items-center">
      {/* RightSide Area */}

      {/* Editor area */}
      <div
        className="
          rounded-md
          p-4
          gap-10
          flex
          justify-center
          items-center
          flex-col
          relative
          overflow-hidden"

        // Add overflow-hidden
      >
        {image && (
          <button
            className={`
              ${showModal ? "bg-blue-500" : "bg-gray-500"}
              hover:bg-blue-700
              text-white
              font-bold
              py-2
              px-4
              text-sm
            `}
            onClick={() => {
              setShowModal(true);
            }}
          >
            <IoPencil />
          </button>
        )}

        {/* Image preview */}
        <div
          className="flex
            w-full
        bg-white
        relative
      "
          style={{
            height: `550px`,
            overflow: "hidden",
          }}
        >
          {/* background */}

          <Tooltip
            id="imagePreview"
            place="top"
            effect="solid"
            type="dark"
            multiline={true}
            className="bg-gray-500 text-white"
          >
            <span>Double click to edit</span>
          </Tooltip>

          <img
            onDoubleClick={() => {
              if (image) {
                setShowModal(true);
              } else {
                setShowModal(false);
              }
            }}
            src={imageUrl}
            alt=""
            className="
             z-20 "
            style={{
              objectFit: "contain",
            }}
          />

          {/* image */}
          {image && (
            <img
              drag
              src={croppedImage ? croppedImage : image}
              alt=""
              style={{
                height: `${height}px`,
                objectFit: "contain",
                position: "absolute",
                left: id === "popsockets" ? "-10px" : "0",
                bottom: `${
                  id === "laptopsleeves"
                    ? "-70px"
                    : id === "popsockets"
                    ? "10px"
                    : "0px"
                }`,
                scale:
                  id === "airpods" ? "0.85" : id === "popsockets" ? "0.6" : "1",
              }}
            />
          )}
        </div>
      </div>
      {/* Model */}

      {showModal && (
        <div
          className="fixed 
          h-full
          w-full
          flex
          justify-center
          items-center
          flex-col
          z-50
          top-0
          left-0
        "
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            backdropFilter: "blur(5px)",
          }}
        >
          <div className="flex flex-col gap-5 p-5">
            {/* Crop Area */}

            <div
              className="flex"
              style={{
                height: "80vh",
                width: "80vw",
                position: "relative",
              }}
            >
              <Cropper
                image={image}
                crop={crop}
                height={550}
                zoom={zoom}
                aspect={ratio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
              {/* slider fot zoom in and zoom out */}
            </div>
            <input
              type="range"
              min={1}
              max={3}
              step={0.001}
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
            />

            <div className="flex gap-5">
              <button
                className={`
              hover:bg-blue-700
              text-white
              py-2
              px-4
              rounded
              text-sm
              bg-blue-500
            `}
                onClick={() => {
                  showCroppedImage();
                }}
              >
                Save
              </button>
              <button
                className={`
              hover:bg-blue-700
              text-white
              py-2
              px-4
              rounded
              text-sm
              bg-gray-500
            `}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
